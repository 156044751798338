$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.dns-records {
  div.subtitle {
    color: $primary;
    font-size: 12px;
    margin: 30px 0 18px 14.3%;
    text-transform: uppercase;
    font-weight: bold;
  }

  .dns-records-wrapper {
    > div:nth-child(2) {
      border-top: 1px solid #ddd;
    }

    div.list-item {
      .r-col {
        .stats {
          > div {
            flex: unset;
          }

          .c-1 {
            width: 14.3%;
          }

          .c-2 {
            margin-left: 10px;
            width: 14.3%;
          }

          .c-3 {
            margin-left: 0;
            width: 14.3%;

            span {
              color: #888;
              font-weight: normal;
            }
          }

          .c-4 {
            width: 550px;

            .stat {
              display: block;
              white-space: normal;
              overflow: hidden;
              width: 100%;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .footer-actions-wrapper {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    padding-bottom: 1.5rem;

    .total {
      margin: 0 3.5rem 0 14.3%;
    }

    .back {
      a {
        padding: 8px 38px;
        color: #777;
        background: #DFDEDD;
        border: 1px solid #DFDEDD;
        border-radius: 3px;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
}