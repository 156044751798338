$secondary: #fcac04;
$secondaryLight: #f8b014;

.content .edit-template.edit-httpd {
  .toolbar {
    .search-toolbar-name {
      width: max-content;

      a {
        color: $secondary;
        text-decoration: none;

        &:hover {
          color: rgb(119, 119, 119);
        }
      }
    }

    .link {
      width: max-content;
      margin-left: 15px;

      a {
        text-decoration: none;
        font-size: 12px;
        text-transform: uppercase;
        color: rgb(119, 119, 119);
        font-weight: 700;

        &:hover {
          color: $secondaryLight;
        }
      }
    }
  }

  textarea {
    width: 75%;
  }

  .checkbox-wrapper label {
    text-transform: capitalize;
  }

  svg {
    margin-left: 5px;
  }
}