$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.password-wrapper {
  display: flex;

  button {
    color: $primary;

    &:hover {
      color: $secondaryLight;
    }
  }

  span.eye {
    margin-left: 9px;
  }

  span.eye-slash {
    margin-left: 7px;
  }
}

button.generate-password {
  color:$primary;
  font-weight: bold;

  &:hover {
    color: $secondaryLight;
  }

  &:active {
    color: $secondaryActive;
  }
}

input {
  color: $textColor;
}

label[for=email] {
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    input {
      height: fit-content;
      margin: 0 10px;
    }

    label {
      margin: 0;
      font-size: 12px;
    }
  }
}