$secondary: #fcac04;
$secondaryLight: #f8b014;
$textColor: #555;

.content .edit-template.edit-php {
  .toolbar {
    .search-toolbar-name {
      width: max-content;
      color: $textColor;
    }
    
    .search-toolbar-name,
    .link {
      width: max-content;

      a {
        text-decoration: none;
        font-size: 12px;
        text-transform: uppercase;
        color: $textColor;
        font-weight: 700;

        &:hover {
          color: $secondaryLight;
        }
      }
    }

    .link {
      margin-left: 15px;

      a {
        color: $secondary;

        &:hover {
          color: $textColor;
        }
      }
    }
  }

  textarea {
    width: 75%;
  }

  .checkbox-wrapper label {
    text-transform: capitalize;
  }

  svg {
    margin-left: 5px;
  }
}