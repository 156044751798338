$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.panel-wrapper {
  .logo-img {
    img {
      width: 82%;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .nav-link {
    padding: 0;
  }

  .left-menu {
    a, button {
      padding: 0 !important;
      outline: none;
    }
    
    > div.nav-link {
      padding: 0;
    }
  }

  .profile-menu {
    > div:nth-child(1) a {
      &:hover {
        color: $secondaryLight;
      }

      &:active {
        color: $secondaryActive;
      }
    }

    > div:nth-child(2) a {
      &:hover {
        color: $secondaryActive;
      }

      &:active {
        color: $secondaryActive;
      }
    }

    .user {
      color: #a4abad;
      font-weight: 700;
    
      &:hover {
        color: $secondaryLight;
      }

      &:active {
        color: $secondaryActive;
      }
    }

    .log-out {
      color: white;
      cursor: pointer;
      font-weight: 100;

      &:hover {
        color: $primaryLight;
      }

      &:active {
        color: $secondaryActive;
      }
    }
  }
}
