$primary: #2c54ac;

div.modal {
  z-index: 2;
}

div.content .modal .modal-content {
  width: 75%;

  .modal-header button.close {
    color: white;
  }

  .modal-footer {
    .btn-primary {
      background: $primary;
      border: 1px solid $primary;
    }
  }
}
