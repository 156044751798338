$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

html {
  overflow-y: scroll;
}

.App {
  font-size: 25px;
  font-family: Arial;
}

.window {
  background: #ececec;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .lists-container {
    display: flex;
    flex-direction: row;
  }
}

.active {
  background: #fff;
}

.progress {
  height: 10px;
  transition: all ease-out 0.1s;
}

.Toastify__toast-body {
  font-size: 20px;
  word-break: break-word;
}
.Toastify__toast-container {
  top: 15em;
}

.actions {
  div {
    a.link-download:hover, button.link-download:hover,
    a.link-edit:hover, button.link-edit:hover {
      background: $primary !important;
    }
    
    a.link-list:hover,
    button.link-list:hover {
      background: $primary !important;
    }

    a.link-delete:hover,
    button.link-delete:hover {
      background: $danger !important;
    }

    a.link-gray:hover,
    button.link-gray:hover {
      background: #afafac !important;
    }

    button {
      text-transform: inherit;
      font-weight: bolder;
      background: #dfdedd;
      border: none;
    }
  }
}

button:active, a:active, .period:active {
  color: $primaryActive;
}

.list-item:first-child {
  margin-top: 5px;
}

.list-item .text-status {
  display: none;
}

button {
  &:hover, &:active, &:focus {
    outline: none;
  }
}

.fixed-buttons.fm {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > div {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 5px;
    border-radius: 50%;
    background: #c3c3c3;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &:hover {
      color: $hoverButtonText;
      background: $primaryLight;
    }
  
    &:active {
      color: $activeButtonText;
      background: $primaryActive;
    }
  
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(1.32);
  
      svg {
        color: white;
        height: 70%;
      }
    }
  }

  > div:first-child {
    background: $primary;

    &:hover {
      color: $hoverButtonText;
      background: $primaryLight;

      button svg {
        color: $hoverButtonText;
      }
    }

    &:active {
      color: $activeButtonText;
      background: $primaryActive;

      button svg {
        color: $activeButtonText;
      }
    }

    button {
      padding: 0;

      svg {
        color: $activeButtonText;
      }
    }
  }
}
