$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.r-menu {
  > div {
    > button {
      font-size: 13px;
      background: white;
      border-color: white;
      color: $textColor;

      &:hover {
        background: white;
        border-color: white;
        color: $textColor;
      }
    }
  }
}

.cron-wrapper {
  .r-col .stats > div {
    flex: none;
    width: 17%;
  }

  .actions {
    > div:nth-child(1) a:hover,
    > div:nth-child(1) button:hover {
      background: $primaryLight;
    }

    > div:nth-child(2) a:hover,
    > div:nth-child(2) button:hover {
      background: #ff3438;
    }
  }
}