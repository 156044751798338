.content .edit-template.edit-pgsql {
  .toolbar {
    .search-toolbar-name {
      width: max-content;
    }
  }

  textarea {
    width: 75%;
  }

  .checkbox-wrapper label {
    text-transform: capitalize;
  }
}