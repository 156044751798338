.web-logs {
  .content {
    padding: 50px 10px 0;
    color: #7d7d7d;
    font-size: 20px;
  }

  .nav-link:nth-child(3),
  .nav-link:nth-child(4) {
    width: 9rem;
  }
}