$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.edit-server {
  .modules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 5px 0;
    }

    > button {
      margin: 15px 0;

      svg {
        margin-left: 10px;
      }
    }

    .form-group {
      a {
        text-decoration: none;
        color: $primary;
        font-weight: bold;
        font-size: 14px;

        &:hover {
          color: $primaryLight;
        }
      }
    }

    .additional-info {
      margin-top: 10px;
      transform: translateX(3rem);

      > div {
        display: flex;
        margin: 5px 0;

        span {
          font-size: 12px;
          color: #555;
        }

        span:nth-child(1) {
          width: 180px;
        }
      }
    }

    .server-web-option,
    .server-dns-option,
    .server-ssl-option,
    .server-mail-option {
      transform: translateX(3rem);
    }

    .server-mail-option {
      transform: translateX(3rem);

      .mail-cert-info {
        transform: translateX(3rem);
      }
    }

    .server-mail-option {
      .hosts {
        transform: translateX(3rem);
      }
    }

    .server-ssl-option {
      transform: translateX(3rem);

      .domain-group {
        transform: translateX(3rem);
      }
    }

    .server-plugins-option {
      transform: translateX(3rem);

      .sftp-module,
      .soft-module,
      .fm-module {
        transform: translateX(3rem);
      }

      .sftp-module,
      .fm-module {
        font-size: 15px;
        color: $textColor;
      }

      .buy-license {
        display: flex;
        margin: 5px 0;

        a {
          color: #FFF;
          background: $primary;
          border: none;
          border-radius: 3px;
          font-size: 13px;
          font-weight: bold;
          padding: 7px 15px;
          text-transform: capitalize;
          text-decoration: none;
          width: max-content;
          margin-right: 10px;

          &:hover {
            color: $hoverButtonText;
            background: $primaryLight;
          }

          &:hover {
            color: $activeButtonText;
            background: $primaryActive;
          }
        }

        + span {
          display: block;
          margin-top: 15px;
          font-style: italic;
          font-size: 12px;
        }
      }

      .license-description {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        > span {
          color: $primary;
        }

        .form-group {
          margin: 0 0 0 20px;
          width: 60%;

          label {
            display: none;
          }
        }
      }
    }
  }
}