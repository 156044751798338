$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.backups-restore-settings {
  .list-item {
    .r-col {
      .stats {
        .c-1 {
          margin: 12px 0 0;
        }
      }
    }

    .actions {
      > div {
        font-weight: bolder;
        text-transform: uppercase;
        height: 38px;
    
        a, button {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $textColor;
          padding: 10px 15px;
          text-decoration: none;
    
          &:hover {
            background: $whiteBackground;
            color: white;
    
            svg {
              color: white;
            }
          }
        }
    
        svg {
          color: $textColor;
        }
      }

      .link-restore {
        svg {
          color: white;
        }

        &:hover {
          color: $hoverButtonText;
          background-color: $primaryLight;
        }
        
        &:hover {
          color: $activeButtonText;
          background-color: $primaryActive;
        }
      }
    }
  }
}
