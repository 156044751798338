$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #eee;

  .login-form-wrapper {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
    font-family: Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 575px;

    .login-layout {
      position: relative;

      > span {
        position: absolute;
        bottom: 0;
        right: 20px;
        font-size: 11px;
        
        a {
          color: #505050;
        }
      }
    }

    form {
      display: flex;
      position: relative;

      > div {
        input[type="text"],
        input[type="password"] {
          width: 100%;
        }
      }

      .c1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;

        img {
          width: 110%;
        }
      }

      .c2 {
        width: 65%;
        margin-left: 3.5rem;

        .forgot-password {
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: .75px;
          text-decoration: none;
        }

        button[type="submit"] {
          background-color: $primary;
          border: 1px solid $primary;
          padding: 1px 16px 3px;
          font-size: 13px;;
          height: 35px;
          color: #fafafa;
          border-radius: 3px;

          &:hover {
            color: $primary;
            border: 1px solid $primaryLight;
            background-color: $primaryLight;
          }

          &:active {
            color: #fafafa;
            border: 1px solid $primary;
            background-color: $primary;
          }

          .disabled {
            cursor: not-allowed;
          }
        }

        div.error-message {
          margin-top: 1rem;
          color: #BE5ABF;
        }
      }
    }
  }
}