$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$danger: #b00e5b;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$textColor: #555;

div.content {
  margin-top: 0;
}

.rrd-list {
  color: $textColor;

  .toolbar {
    padding: 6px 13%;
  }

  .rrd-item {
    padding: 25px 0;
    align-items: flex-start;

    .actions {
      opacity: 0;
    }

    .l-col {
      display: flex;
      flex-direction: column;
      margin: 15px 40px 0 0;

      .date {
        margin: 0;
      }
    }

    &:hover {
      .actions {
        opacity: 1;
      }
    }
  }
}

.periods-wrapper {
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    margin-left: 8px;
  }

  > div {
    cursor: pointer;
    margin: 0 15px;
    font-size: 14px;

    &:hover {
      color: $secondaryLight;
    }

    &:active {
      color: $primaryActive;
    }
  }

  .timer-wrapper {
    > button svg {

      &:hover {
        color: $secondaryLight;
      }

      &:active {
        color: $primaryActive;
      }
    }

    > div.circle-wrapper {
      cursor: default;
    }
  }

  > div.active {
    color: $secondaryActive;
  }
}

@media (max-width: 1350px) {
  .rrd-list .toolbar {
    padding: 6px 9.5%;
  }
}
