.spinner-wrapper .progress {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  margin: 0;
  height: 6px;
  display: inline-table;

  .progress-bar {
    height: 10px;
  }
}